<script setup lang="ts">
const { referential } = useReferentialStore()
</script>

<template>
  <div class="flex items-center mt-4">
    <div>
      <YscIconsWarningTriangle class="h-5 w-5 mr-4" />
    </div>
    <div>
      <p class="font-semibold">
        {{ $t('components.app_modal_booking_owner_cancel.proof_required') }}
      </p>
      <i18n-t
        keypath="components.app_modal_booking_owner_cancel.send_proof_dynamic"
        tag="p"
        scope="global"
      >
        <template #contact_string>
          <NuxtLink
            :to="`mailto:${referential?.email_address}`"
            class="link link-primary"
          >
            {{ referential?.email_address }}
          </NuxtLink>
        </template>
      </i18n-t>
    </div>
  </div>
</template>

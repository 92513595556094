import type { BookingOwnerDetails } from '@yescapa-dev/ysc-api-js/legacy'
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'

export const useBookingStateSummary = (booking: BookingOwnerDetails | null) => {
  if (!booking) return null
  const bookingState = getTravelStateEnhanced(booking)

  if (!bookingState) {
    return null
  }

  const { VALIDATED, ACCEPTED, TO_COME_NOT_PAID, TO_COME } = Constants.BOOKINGS.STATUS
  const { ONGOING } = STATUS_ENHANCED
  const { CONFIRMED } = Constants.BOOKINGS.LISTING_STATE

  if (bookingState === VALIDATED || bookingState === ACCEPTED || bookingState === TO_COME_NOT_PAID) {
    return STATUS_SUMMARY.PENDING
  }

  if (bookingState === TO_COME || bookingState === ONGOING || booking.meta_state === CONFIRMED) {
    return STATUS_SUMMARY.CONFIRMED
  }

  return STATUS_SUMMARY.DONE
}

<script setup lang="ts">
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { BookingOwnerRefusePayload } from '@yescapa-dev/ysc-api-js/legacy'
import { CHAT } from '~/constants/modal'
import type { SubmitAction } from '~/types/commons'
import { YSC_API_BOOKING_OWNER_ERROR } from '~/utils/error/YscErrorClasses'

const route = useRoute()

const showModalChat = ref(queryValueToString(route.query.more) === CHAT)
const showModalRefuse = ref(false)
const showGuestReviews = ref(false)

const userType = Constants.USERS.TYPES.OWNER
const { $errorManager } = useErrorManager()

const { fetchGuestReviews, fetchBookingOwner, fetchBookingOwnerPrices } = useBookingsOwnerStore()
const { bookingOwner, prices, guestReviews } = storeToRefs(useBookingsOwnerStore())
const { getReferentialMaps, getReferentialOptions } = useReferentialStore()
const { routing } = storeToRefs(useRoutingStore())
const { t } = useI18n()
const { $api } = useYscApi()
const bookingId = queryValueToNumber(route.params.id)

const { pending, refresh } = await useAsyncData(`d-bookings-${route.params.id}`, async () => {
  if (!bookingId) {
    return navigateTo({ name: 'bookings' })
  }
  const [bookingOwnerRequest, bookingOwnerPricesRequest] = await Promise.allSettled([
    fetchBookingOwner({ id: bookingId, refresh: true }),
    fetchBookingOwnerPrices({ id: bookingId, refresh: true }),
  ])
  const { $errorManager } = useErrorManager()
  if (bookingOwnerRequest.status === 'rejected') {
    $errorManager({ e: bookingOwnerRequest.reason, name: YSC_API_BOOKING_OWNER_ERROR })
  }

  if (bookingOwnerPricesRequest.status === 'rejected') {
    $errorManager({ e: bookingOwnerPricesRequest.reason, name: YSC_API_BOOKING_OWNER_ERROR })
  }

  return true
})

useSeoText({ title: `${t('commons.booking_owner')} ${bookingId}` })

const { user } = storeToRefs(useUserStore())

const insuranceVerbose = computed(() => useInsuranceProviderText({ insuranceCoverage: bookingOwner.value?.insurance_coverage }))

const isConfirmed = computed(() => useBookingStateSummary(bookingOwner.value) === STATUS_SUMMARY.CONFIRMED)

const alertCamperIsMissingDocuments = computed(() => bookingOwner.value?.state === Constants.BOOKINGS.STATUS.VALIDATED && !bookingOwner.value?.permissions.can_owner_accept_booking)

const isBookingInsuredByYescapa = computed(() => bookingOwner.value?.insurance.slug !== Constants.INSURANCES.OWNER_MANAGED_INSURANCE_SLUG)

const travelDocuments = computed(() =>
  bookingOwner.value
    ? useTravelDocuments({
      booking: bookingOwner.value,
      moreLinks: {
        contractTerms: routing.value?.contract,
        insuranceTerms: routing.value?.list_insurances,
      },
    })
    : [],
)

const stateEnhanced = computed(() => bookingOwner.value ? getTravelStateEnhanced(bookingOwner.value) : null)

const canRemoveExtensions = computed(() => isConfirmed.value && bookingOwner.value?.extensions.state === Constants.EXTENSIONS.STATUS.GUEST_ASKED)

const canAcceptOrRefuseBooking = computed(() => bookingOwner.value?.state === Constants.BOOKINGS.STATUS.VALIDATED)

const guestLanguages = useSpokenLanguagesSentence(bookingOwner.value?.guest.spoken_languages)

const onAcceptBooking: SubmitAction<{ id: number }> = async ({ form } = {}) => {
  const formValue = toValue(form)
  if (!formValue) return
  try {
    await $api.bookings.acceptBooking(formValue.id)
    await refresh()
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_BOOKING_OWNER_ERROR })
    }
  }
}

const { stackSuccess } = useToastsStore()
const onRefuseBooking: SubmitAction<BookingOwnerRefusePayload> = async ({ form } = {}) => {
  if (!bookingId || !form) {
    return
  }
  const formValue = toValue(form)
  try {
    await $api.bookings.refuseBooking(bookingId, formValue)
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_BOOKING_OWNER_ERROR })
    }
    return
  }
  showModalRefuse.value = false
  stackSuccess({
    label: { text: 'components.app_booking_quick_actions.refused', needTranslation: true },
  })
  await refresh()
}

const onRefuseExtensions: SubmitAction = async () => {
  if (!bookingId) {
    return
  }
  try {
    await $api.bookings.refuseExtensions(bookingId)
    await refresh()
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_BOOKING_OWNER_ERROR })
    }
  }
}

const onOpenGuestReviewsModal = async () => {
  showGuestReviews.value = true

  try {
    await fetchGuestReviews()
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e }) // FIXME qualify error
    }
  }
}

const onChatClose = () => {
  showModalChat.value = false
  navigateTo({ name: 'd-bookings-id' })
}

const formatKilometerPerDay = useFormatKilometerPerDay()
</script>

<template>
  <div>
    <AppLoadingOverlay v-if="pending || !bookingOwner" />

    <AppLayoutTwoOneThird v-else>
      <template #two-third>
        <div class="space-y-8">
          <AppBookingOwnerActions
            v-if="stateEnhanced"
            :id="bookingOwner.id"
            :state-enhanced="stateEnhanced"
            :state-summary="useBookingStateSummary(bookingOwner)"
            :id-camper="bookingOwner.camper.id"
            :extension-state="bookingOwner.extensions.state"
            :typeform-sinister-url="bookingOwner.sinister_declaration_url"
            :is-booking-insured-by-yescapa="isBookingInsuredByYescapa"
            :can-accept-booking="canAcceptOrRefuseBooking"
            :can-refuse-booking="canAcceptOrRefuseBooking"
            :can-refuse-extension="canRemoveExtensions"
            :alert-guest-is-missing-documents="isConfirmed && !bookingOwner.guest.are_documents_valid"
            :alert-camper-is-missing-documents="alertCamperIsMissingDocuments"
            :review="bookingOwner.review"
            :is-review-allowed="bookingOwner.permissions.is_owner_review_allowed"
            :is-cancellation-allowed="bookingOwner.permissions.is_cancellation_allowed"
            :owner-cancel-main-reason="bookingOwner.owner_cancel.main_reason"
            :acceptation-expired-on="bookingOwner.owner_accept_expire_on"
            :accept-booking="onAcceptBooking"
            :refuse-extension="onRefuseExtensions"
            @refuse-booking="showModalRefuse = true"
          />

          <AppTravelDocumentList
            v-if="travelDocuments.length"
            :travel-documents="travelDocuments"
            :display-numeric-contract-push="!!bookingOwner.physical_contract_url"
          />

          <AppTravelTips
            v-if="isConfirmed && !bookingOwner.is_professional"
            :is-yescapa-deposit-guaranty="bookingOwner.is_yescapa_deposit"
            :has-insurance="!!bookingOwner.insurance.slug"
            is-owner
          />

          <YscCard>
            <template #title>
              {{ $t('components.app_user_card.guest.title') }}
            </template>

            <template
              v-if="bookingOwner.permissions.is_conversation_allowed"
              #action
            >
              <button
                class="btn btn-secondary text-sm"
                @click="showModalChat = true"
              >
                {{ $t('commons.actions.send_message') }}
              </button>
            </template>
            <AppUserDetails
              v-if="bookingOwner"
              :first-name="bookingOwner.guest.first_name"
              :last-name="bookingOwner.guest.last_name"
              :src-avatar="bookingOwner.guest.profile_picture?.url"
              :phone="bookingOwner.guest.phone"
              :email="bookingOwner.guest.email"
              :booking-count="bookingOwner.guest.bookings_as_guest"
              :review-count="bookingOwner.guest.review_count_as_guest"
              :review-average="bookingOwner.guest.review_average_as_guest"
              :is-id-certified="bookingOwner.guest.is_id_certified"
              :is-phone-certified="bookingOwner.guest.is_phone_certified"
              :languages="guestLanguages"
              show-user-detail
              @show-reviews="onOpenGuestReviewsModal"
            />
          </YscCard>

          <AppPriceCard
            v-if="prices"
            :prices="prices"
            :camper-image-path="bookingOwner.camper.cover_image?.path"
            :camper-title="useVehicleType({ type: bookingOwner.camper.type })"
            :camper-city="bookingOwner.camper.location.city"
            :camper-passengers="bookingOwner.travelers || 0"
            :total-km="bookingOwner.total_km"
            :km-option="bookingOwner.km_option"
            :user-type="userType"
            :is-self-insured="bookingOwner.camper.self_insured"
            :camper-path="{ name: 'campers-id', params: { id: bookingOwner.camper.id } }"
          >
            <div
              v-if="bookingOwner.bill_url"
              class="mt-4"
            >
              <NuxtLink
                :to="bookingOwner.bill_url"
                class="link link-primary"
              >
                {{ $t('components.app_price_details.bill_link_text') }}
              </NuxtLink>
            </div>
          </AppPriceCard>
        </div>
      </template>

      <template #one-third>
        <div class="space-y-8">
          <AppTravelSummary>
            <template #booking-id>
              {{ bookingOwner.id }}
            </template>
            <template #vehicle-registration>
              {{ bookingOwner.camper.registration }}
            </template>
            <template
              v-if="bookingOwner.date_time_from"
              #date-from
            >
              {{ $d(new Date(bookingOwner.date_time_from), 'Y.numeric M.long D.numeric') }}
            </template>
            <template
              v-if="bookingOwner.date_time_from"
              #hour-from
            >
              {{
                $t('data.utils.hour_from.label_dynamic', {
                  hour_string: $d(new Date(bookingOwner.date_time_from), 'h.numeric'),
                })
              }}
            </template>

            <template
              v-if="bookingOwner.date_time_to"
              #date-to
            >
              {{ $d(new Date(bookingOwner.date_time_to), 'Y.numeric M.long D.numeric') }}
            </template>
            <template
              v-if="bookingOwner.date_time_to"
              #hour-to
            >
              {{
                $t('data.utils.hour_to.label_dynamic', {
                  hour_string: $d(new Date(bookingOwner.date_time_to), 'h.numeric'),
                })
              }}
            </template>
            <template
              v-if="bookingOwner.travelers"
              #travelers
            >
              {{ $n(bookingOwner.travelers) }}
            </template>

            <template
              v-if="bookingOwner.total_earnings"
              #remuneration
            >
              {{ $n(bookingOwner.total_earnings, getReferentialMaps('currency')[bookingOwner.total_earnings_currency]) }}
            </template>

            <template #insurance>
              {{ insuranceVerbose }}
            </template>

            <template
              v-if="!bookingOwner.is_yescapa_deposit && bookingOwner.camper.deposit && bookingOwner.camper.deposit_currency"
              #deposit
            >
              {{ $n(bookingOwner.camper.deposit, getReferentialMaps('currency')[bookingOwner.camper.deposit_currency]) }}
            </template>
            <template
              v-if="!bookingOwner.is_yescapa_deposit && bookingOwner.camper.deposit_means"
              #deposit-means
            >
              {{ bookingOwner.camper.deposit_means.map((x) => getReferentialMaps('deposit_mean')[x]).join(', ') }}
            </template>

            <template
              v-if="bookingOwner.km_option !== undefined"
              #km-option
            >
              {{ formatKilometerPerDay({ count: bookingOwner.km_option }) }}
            </template>
            <template
              v-if="bookingOwner.km_option && bookingOwner.total_km"
              #km-option-details
            >
              {{
                $t('data.booking.km_option.description_total_dynamic', {
                  km_total_string: $n(bookingOwner.total_km, { style: 'unit', unit: 'kilometer' }),
                })
              }}
            </template>

            <template #travelled-countries>
              {{ bookingOwner.countries.map((x) => (getReferentialOptions('country').find(({ value }) => x === value) || { text: '' }).text).join(', ') }}
            </template>
          </AppTravelSummary>

          <HoChat
            v-if="bookingOwner.conversation_id && user?.id"
            :id-user="user.id"
            :id-chat="bookingOwner.conversation_id"
            :show-modal="showModalChat"
            :can-send-messages="bookingOwner.permissions.is_conversation_allowed"
            @close="onChatClose"
          />

          <YscTipList
            v-if="!bookingOwner.camper.is_half_day_activated"
            :tip-list="[
              {
                title: $t('components.ysc_tip.tip_why_half_day.title'),
                description: $t('components.ysc_tip.tip_why_half_day.description'),
              },
            ]"
          />
        </div>
      </template>
    </AppLayoutTwoOneThird>

    <AppModalBookingRefuse
      v-if="showModalRefuse && bookingOwner"
      :id-camper="bookingOwner.camper.id"
      :submit-action="onRefuseBooking"
      @close="showModalRefuse = false"
    />

    <YscModal
      v-if="showGuestReviews && bookingOwner"
      @close="showGuestReviews = false"
    >
      <template #title>
        {{ $t('pages.booking_id_index.guest_review_modal_title_dynamic', { name: bookingOwner.guest.first_name }) }}
      </template>
      <div
        v-if="guestReviews"
        class="space-y-8"
      >
        <AppReview
          v-for="review in guestReviews.results"
          :key="review.id"
          :note="review.avg"
          :photos="review.photos"
          is-large-photos
        >
          <template #avatar>
            <YscAvatar
              :first-name="review.author.first_name"
              :picture="review.author.photo"
              radius="28"
            />
          </template>

          <template #user-name>
            {{ review.author.first_name }}
          </template>

          <template
            v-if="review.published_date"
            #user-description
          >
            {{ $d(new Date(review.published_date), 'Y.numeric M.long D.numeric') }}
          </template>

          <template #description>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="review.text" />
          </template>
        </AppReview>
      </div>
    </YscModal>
  </div>
</template>
